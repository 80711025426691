import React, { useEffect, useState } from "react"
import { CenterAligned, Spinner, Text } from "@opensea/ui-kit"
import { NextPage } from "next"
import { getAppInitialProps } from "@/components/app/initialProps"
import { ConnectCompatibleWallet } from "@/components/nav/WalletSidebar/ConnectCompatibleWallet"
import { OpenSeaPage } from "@/containers/OpenSeaPage.react"
import { useWalletModal } from "@/containers/WalletModalProvider.react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { useIsCustomAuthWalletEnabled } from "@/features/os-wallet"
import { OpenSeaHead, pageTitle } from "@/features/seo"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { first } from "@/lib/helpers/array"
import { escapeRelativeUrl } from "@/lib/helpers/urls"

export const LoginPage: NextPage = () => {
  const t = useTranslate("login")
  const router = useRouter()
  const isCustomAuthWalletEnabled = useIsCustomAuthWalletEnabled()

  const { isAuthenticated, providers } = useWallet()
  const { startWalletModalAuthFlow, closeWalletModal } = useWalletModal()

  const provider = first(providers)
  const isInstalled = provider !== undefined

  const [isPendingRedirect, setIsPendingRedirect] = useState(false)

  useMountEffect(() => {
    if (isCustomAuthWalletEnabled) {
      startWalletModalAuthFlow(
        () => {
          setIsPendingRedirect(true)
        },
        () => {
          setIsPendingRedirect(true)
          router.replace("/")
        },
      )
    }
  })

  useEffect(() => {
    if (isCustomAuthWalletEnabled && isAuthenticated) {
      closeWalletModal()
      let referrer = router.query.referrer as string | undefined
      if (!referrer) {
        if (router.asPathWithoutQuery !== "/login") {
          // After a server side redirect to login page, sometimes we see the path
          // redirected from remains in the URL despite the login page showing.
          // This is a workaround that assumes the URL path is the expected referrer
          // if the login page is rendered with URL path other than /login.
          referrer = router.asPath
        } else {
          // Use account page as default referrer if on login page
          referrer = "/account"
        }
      }
      router.replace(escapeRelativeUrl(referrer))
    }
  }, [closeWalletModal, isAuthenticated, isCustomAuthWalletEnabled, router])

  return (
    <OpenSeaPage>
      <OpenSeaHead title={pageTitle(t("page.title", "Login"))} />

      {isCustomAuthWalletEnabled ? (
        <CenterAligned className="h-[600px] sm:h-[800px]">
          {isPendingRedirect && <Spinner size="large" />}
        </CenterAligned>
      ) : (
        <Block marginX="auto" padding="20px">
          <Text.Heading asChild color="primary" size="medium">
            <h1>
              {isInstalled
                ? t("page.body", "Connect your wallet.")
                : t(
                    "page.crypto.body",
                    "You need a crypto wallet to use OpenSea.",
                  )}
            </h1>
          </Text.Heading>
          <ConnectCompatibleWallet source="wallet page" />
        </Block>
      )}
    </OpenSeaPage>
  )
}

LoginPage.getInitialProps = ctx => {
  return getAppInitialProps(ctx)
}
